import React from "react";
import { Link } from "gatsby-theme-material-ui";
import {
  Toolbar,
  AppBar,
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import JobPage from "../components/job-page";
import Seo from "../components/seo";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ display: "flex" }}
      {...other}
    >
      {value === index && <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const NavTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface NavTabProps {
  label: string;
}

const NavTab = styled((props: NavTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function App() {
  const [tab, setTab] = React.useState(0);
  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Seo />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h3" mr={2}>
            Pexabit
          </Typography>
          <NavTabs value={tab} onChange={handleTab}>
            <NavTab label="About Us" {...a11yProps(0)} />
            <NavTab label="Careers" {...a11yProps(1)} />
          </NavTabs>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ display: "flex", flexGrow: 1 }}>
        <TabPanel value={tab} index={0}>
          <p style={{ paddingTop: "100px" }}>
            Pexabit is a quantitative trading startup that uses market intuition
            to build latency sensitive mathematical models for trading in the
            capital markets. We are a small, fully-remote and globally
            distributed team that has worked together for the past couple of
            years and built a profitable trading strategy.
          </p>
          <Link to="mailto:inquiries@pexabit.com">inquiries@pexabit.com</Link>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <JobPage />
        </TabPanel>
      </Container>
      <AppBar position="static" sx={{ alignSelf: "flex-end" }}>
        <Typography align="center">Copyright © 2022 Pexabit LLC</Typography>
      </AppBar>
    </Box>
  );
}
