import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface JobPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function JobPanel(props: JobPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="jobpanel"
      hidden={value !== index}
      id={`simple-jobpanel-${index}`}
      aria-labelledby={`simple-job-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function b11yProps(index: number) {
  return {
    id: `simple-job-${index}`,
    "aria-controls": `simple-jobpanel-${index}`,
  };
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const JobTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  marginTop: 40,
  marginBottom: 50,
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

interface JobTabProps {
  label: string;
}

const JobTab = styled((props: JobTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(30),
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function section(header: string, content) {
  return (
    <React.Fragment>
      <Typography variant="h4" fontWeight="medium">
        {header}
      </Typography>
      {content}
    </React.Fragment>
  );
}

export default function JobPage() {
  const [job, setJob] = React.useState(0);
  const handleJob = (event: React.SyntheticEvent, newValue: number) => {
    setJob(newValue);
  };

  return (
    <Box>
      <JobTabs value={job} onChange={handleJob} centered>
        <JobTab label="C++ Engineer" {...b11yProps(0)} />
      </JobTabs>
      <JobPanel value={job} index={0}>
        {section(
          "About You",
          <p>
            You are excited to grow a company together. You will work on a
            low-latency concurrent trading system written in C/C++ that consumes
            order data from the exchange in real-time and sends orders based on
            it as fast as possible (think 10s of microseconds).
          </p>
        )}
        {section(
          "Expectations",
          <ul>
            <li>You will work with a modern C/C++ technology stack</li>
            <li>You will write Python scripts to automate tasks</li>
            <li>
              You will work in a fast-paced environment and are comfortable with
              constantly changing requirements
            </li>
            <li>
              You can turn vague requirements into a functional product and are
              open to constructive criticism
            </li>
            <li>
              You are open to learning about financial markets and quantitative
              trading
            </li>
          </ul>
        )}
        {section(
          "Qualifications",
          <ul>
            <li>
              You are proficient in low-level programming and have a deep
              knowledge of computer architecture, operating systems, concurrency
              and networking protocols
            </li>
            <li>You are proficient in C/C++ and comfortable with Python</li>
          </ul>
        )}
        {section(
          "Compensation",
          <ul>
            <li>Competitive cash compensation</li>
            <li>Profit sharing from our trading operation</li>
            <li>Flexible work hours</li>
          </ul>
        )}
        {section(
          "Possible Projects",
          <ul>
            <li>Overhaul our user-space network stack</li>
            <li>Profile and optimize the trading system</li>
          </ul>
        )}
        {section(
          "Sounds Fun?",
          <p>
            Send us your resume @
            <Link to="mailto:careers@pexabit.com">careers@pexabit.com</Link>
          </p>
        )}
      </JobPanel>
    </Box>
  );
}
